import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {
    convertIntoDate,
    deleteDatabasesMapping,
    fetchDatabasesMapping,
    fetchSourceMessages,
    withErrorHandlingAndLoading
} from "../../constants/functions";
import Loading from "../../Components/Loading/Loading";
import { SmallCard } from "../../Components/SmallCard";
import { MessageCard } from "../../Components/MessageCard";
import Alert from "@material-ui/lab/Alert";
import { useStateValue } from "../../StateContext";
import { actionTypes } from "../../StateContext/types";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
    tableContainer: {
        flexGrow: 1,
        overflow: 'auto',
        '& .MuiTableCell-root': {
            padding: '15px',
        },
        '& .MuiPaper-elevation4': {
            boxShadow: '0 0 0 0',
        },
    },
    fallbackMessage: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.text.secondary,
    },
    paginationControls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
    pageInfo: {
        margin: theme.spacing(0, 2),
    },
    messagesContainer: {
        flex: 1,
        overflowY: 'auto', // Enable vertical scrolling
        maxHeight: 'calc(100vh - 250px - 100px)', // Adjust height based on screen and header/pagination
    },
}));

const Sources = ({ handleAlert }) => {
    const classes = useStyles();
    const { sourcesState, sourcesDispatch } = useStateValue();
    const [source, setSource] = useState({ id: '', name: '' });
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalMessages, setTotalMessages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deletedId, setDeletedId] = React.useState(null);

    const [dialogType, setDialogType] = useState(null);
    const openDialog = (type) => { setDialogType(type); };
    const closeDialog = () => { setDialogType(null); };

    const handleSourceDelete = withErrorHandlingAndLoading(async () => {
        await deleteDatabasesMapping(deletedId);
        await sourcesDispatch({
            type: actionTypes.DELETE_SOURCE,
            sourceId: deletedId
        });
        closeDialog();
    }, setLoading, handleAlert);

    useEffect(() => {
        const getDatabasesMapping = withErrorHandlingAndLoading(async () => {
            const sources = await fetchDatabasesMapping();
            sources.length > 0 && await setSource({ id: sources[0].id, name: sources[0].source_name });
            await sourcesDispatch({
                type: actionTypes.SET_SOURCES,
                value: sources
            });
        }, setLoading, handleAlert);

        getDatabasesMapping();
    }, []);

    useEffect(() => {
        const fetchMessages = withErrorHandlingAndLoading(async () => {
            const initialState = { fetchedMessages: [], total: 0 };
            const { messages: fetchedMessages, total } = source.id ? await fetchSourceMessages(source.id, page, pageSize) : initialState;
            setMessages(fetchedMessages);
            setTotalMessages(total);
        }, setLoading, handleAlert);

        if (source.id) {
            fetchMessages();
        }
    }, [page, pageSize, source.id, fetchTrigger]);

    const handlePlayClick = (id, name) => {
        setMessages([]);
        setSource({ id, name });
        setFetchTrigger(prev => !prev);
    };

    const handleOpenClick = (message) => {
        setMessage({ message: message._source || message });
        openDialog(3);
    };

    const handleNextPage = () => {
        if (page * pageSize < totalMessages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <>
            <Grid container style={{ height: 'calc(100vh - 250px)' }} spacing={2}>
                {/* Left Grid (Table) */}
                <Grid item xs={12} md={5} style={{ height: '100%' }}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>DataStore Type</TableCell>
                                    <TableCell>DataStore Name</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sourcesState.map((val) => (
                                    <TableRow key={val.id} selected={val.id === source.id}>
                                        <TableCell>{val.id}</TableCell>
                                        <TableCell component="th" scope="row">{val.dbname}</TableCell>
                                        <TableCell component="th" scope="row">{val.source_name}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="play" onClick={() => handlePlayClick(val.id, val.source_name)}>
                                                <PlaylistPlayIcon />
                                            </IconButton>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    setDeletedId(val.id);
                                                    openDialog(1);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {/* Right Grid (Messages) */}
                <Grid item xs={12} md={7} style={{ height: '100%' }}>
                    <Paper style={{ height: '100%', padding: 10, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant={"h6"}>Messages</Typography>
                        <Divider style={{ margin: 5 }} />
                        <Grid container spacing={1} className={classes.messagesContainer}>
                            {loading ? (
                                <Grid container justifyContent="center">
                                    <Loading />
                                </Grid>
                            ) : messages.length === 0 ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 20, height: '100%' }}>
                                    <Typography>No messages available</Typography>
                                </Grid>
                            ) : (
                                messages?.map((message, index) => (
                                    <Grid style={{ padding: '10px' }} item lg={4} md={6} sm={12} xs={12} key={index}>
                                        <SmallCard
                                            name={source.name}
                                            timestamp={message._source ? convertIntoDate(message._source.creationDate) : convertIntoDate(message.creationDate)}
                                            message={message}
                                            onOpen={() => handleOpenClick(message)}
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                        {messages?.length !== 0 && (
                            <div className={classes.paginationControls} style={{ marginTop: '20px', alignSelf: 'flex-end' }}>
                                <IconButton
                                    color="primary"
                                    onClick={handlePreviousPage}
                                    disabled={page === 1}
                                    size={"small"}
                                >
                                    <ArrowBack />
                                </IconButton>
                                <Typography variant="caption" className={classes.pageInfo}>
                                    Page {page} of {Math.ceil(totalMessages / pageSize)}
                                </Typography>
                                <IconButton
                                    color="primary"
                                    onClick={handleNextPage}
                                    disabled={page * pageSize >= totalMessages}
                                    size={"small"}
                                >
                                    <ArrowForward />
                                </IconButton>
                            </div>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Dialogs */}
            <Dialog open={dialogType === 1} onClose={closeDialog}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this source?
                    <Alert severity={"error"}>Note that deleting the data store will delete all of its data and its associated sink connector!!</Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSourceDelete} color='primary' size='small'>Yes</Button>
                    <Button onClick={closeDialog} color='primary' size='small'>No</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogType === 3} onClose={closeDialog}>
                <DialogTitle disableTypography={false}>{source.name}</DialogTitle>
                <DialogContent dividers>
                    <MessageCard
                        name={source.name}
                        message={message._source ? message._source : message}
                        handleAlert={handleAlert}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Sources;