import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import { SearchInput, StatusBullet } from "../../Components";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {Paper, TableContainer, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Drawer from '@material-ui/core/Drawer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from "@material-ui/core/TablePagination";
import { useStateValue } from "../../StateContext";
import {
    convertIntoDate,
    deleteThing,
    fetchThings,
    withErrorHandlingAndLoading
} from "../../constants/functions";
import { actionTypes } from "../../StateContext/types";
import Loading from "../../Components/Loading/Loading";
import ThingDetails from './ThingDetails';
import MessageAlert from "../../Components/Alert";
import CardHeader from "@material-ui/core/CardHeader";
import ThingForm from "./components/ThingForm";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    padding: {
        padding: theme.spacing(1),
    },
    username: {
        padding: theme.spacing(1),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: theme.palette.background.paper,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: "65px",
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    searchInput: {
        marginRight: theme.spacing(1),
        width: '100%',
        maxWidth: 300,
    },
    tableContainer: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 200px)',
        '& .MuiTableCell-root': {
            padding: '15px', // Adjust the padding as needed
        },
        '& .MuiPaper-elevation4': {
            boxShadow: '0 0 0 0', // Adjust the padding as needed
        },
    },
}));

const Acls = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { thingsState, thingsDispatch } = useStateValue();
    const [value, setValue] = React.useState(0);
    const [selectData, setSelectedData] = React.useState({});
    const [thingName, setThingName] = useState('');
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open = Boolean(anchorEl2);

    const [alert, setAlert] = useState({
        open: false, message: "", severity: "info"
    });
    const handleAlert = (open, message, severity) => {
        setAlert({
            open: open,
            message: message,
            severity: severity
        })
    }
    const handleClose = () => {
        setAlert(prevState => ({ ...prevState, open: false }));
    };

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => () => {
        setState({ ...state, [anchor]: open });
    };
    const handleChange = (event, newValue) => { setValue(newValue); };

    const handleThingNameChange = event => { setThingName(event.target.value) };

    const [openNew, setOpenNew] = React.useState(false);

    const handleClickOpen = () => {
        setOpenNew(true);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const [loading, setLoading] = React.useState(false);

    const [delLoading, setDelLoading] = React.useState(false);

    const [openDelete, setOpenDelete] = useState(false);

    const handleOpenDelete = () => { setOpenDelete(true); }

    const handleCloseDelete = () => { setOpenDelete(false); }

    const handleDelete = withErrorHandlingAndLoading(async () => {
        await handleClose2();
        await deleteThing(selectData.thing_id);
        await thingsDispatch({
            type: actionTypes.DELETE_THING,
            thingId: selectData.thing_id
        });
        await handleCloseDelete();
    }, setDelLoading, handleAlert)

    const handleActionClick = async (event, value) => {
        setSelectedData(value);
        setThingName(value.name);
        setAnchorEl2(event.currentTarget);
    };

    useEffect(() => {

        const fetchData = withErrorHandlingAndLoading(async () => {

            const response = await fetchThings();
            await thingsDispatch({
                type: actionTypes.SET_THINGS,
                value: response
            })
        }, setLoading, handleAlert)

        fetchData()
    }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filteredData = thingsState.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handlePageChange = (event, newPage) => setPage(newPage);
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <MessageAlert open={alert.open} message={alert.message} onClose={handleClose} severity={alert.severity} />

            <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)} classes={{ paper: classes.drawerPaper, }}>
                <ThingDetails
                    selectData={selectData}
                    handleThingNameChange={handleThingNameChange}
                    loading={loading}
                    handleChange={handleChange}
                    thingName={thingName}
                    value={value}
                    toggleDrawer={toggleDrawer}
                    handleAlert={handleAlert}
                />
            </Drawer>

            <Grid container spacing={3} justifyContent="space-between">
                <Grid item xs={6} sm={6} md={4}>
                    <div className={classes.row}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search thing"
                            name={"search"}
                            onChange={handleSearchChange}
                        />
                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={4} style={{ textAlign: isMobile ? 'center' : 'right' }}>
                    <Button variant="contained" color="primary" onClick={handleClickOpen} className={classes.btn}>
                        Add thing
                    </Button>
                </Grid>

                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={12}>
                    <Menu
                        id={`long-menu`}
                        anchorEl={anchorEl2}
                        keepMounted
                        open={open}
                        onClose={handleClose2}
                        PaperProps={{ style: { maxHeight: 48 * 4.5, width: '20ch' }, }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem key={`1`} selected={"1" === 'Pyxis'} onClick={toggleDrawer("right", true)}>
                            {"Details"}
                        </MenuItem>
                        <MenuItem key={`4`} selected={"4" === 'Pyxis'} onClick={handleOpenDelete}>
                            {"Delete "}
                        </MenuItem>
                    </Menu>

                    <Grid  item xs={12}>
                        {loading ? <Loading /> :
                            <Card>
                                <CardHeader title="List of Things" />

                                <Grid  item xs={12}><Divider/></Grid>
                                <TableContainer className={classes.tableContainer}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="center">N° Messages</TableCell>
                                                <TableCell align="center">Created</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                                <TableCell align="center">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((value) => (
                                                    <TableRow key={value.id}>
                                                        <TableCell>
                                                            <Grid container alignItems="center">
                                                                <StatusBullet color="success" size="sm" style={{ marginRight: 10 }} />
                                                                <Typography variant="body1">{value.name}</Typography>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align="center">{value.messages_count}</TableCell>
                                                        <TableCell align="center">{convertIntoDate(value.created_at)}</TableCell>
                                                        <TableCell align="center">Running</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton edge="end" onClick={event => handleActionClick(event, value)}>
                                                                <MoreVertIcon color={"primary"} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={filteredData.length}
                                    page={page}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPageOptions={[5, 10, 15]}
                                />
                            </Card>
                        }
                    </Grid>
                </Grid>
            </Grid>

            <Dialog open={openNew} onClose={handleCloseNew} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"> New Thing</DialogTitle>

                <ThingForm handleAlert={handleAlert} handleCloseNew={handleCloseNew} open={openNew} />
            </Dialog>

            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this thing?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color='primary' size='small'> yes </Button>
                    <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                    {
                        delLoading && <Loading size={24}/>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Acls;
