import React, {useEffect, useState} from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useStateValue} from "./StateContext";
import MessageAlert from "./Components/Alert";
import {getTokenFromLocalStorage} from "./constants/functions";
import Layout from "./Layout";

import Routes from "./Routes";
import {actionTypes} from "./StateContext/types";

const browserHistory = createBrowserHistory();
const routes = Routes();


export default function App() {

    const { notificationsDispatch} = useStateValue();

    const [alert, setAlert] = useState({
        open : false , message : "" , severity : ""
    });

    const handleClose = () => {setAlert({ open : false , message : "" , severity : "info"})};

    const handleAlert = (open, message, severity) =>{
        setAlert({
            open : open ,
            message :message ,
            severity: severity
        })
    }

    useEffect(() => {
        const token = getTokenFromLocalStorage();
        if(token) {
            const ws = new WebSocket(process.env.REACT_APP_WS_URL ,  [token.split(' ')[1]]);

            ws.addEventListener('open', () => {
                console.log('Connected to server');
            });

            ws.addEventListener('message', async (event) => {
                const data = JSON.parse(event.data);
                console.log('Received message:', data);
                handleAlert(true , data , 'info')
                /*await notificationsDispatch({
                    type : actionTypes.ADD_NOTIFICATION,
                    notification : data.message
                });*/
            });

            ws.addEventListener('ping', (event) => {
                ws.pong();
                console.log('Received ping, sent pong');
            });

            return () => {
                ws.close(); // Disconnect when component unmounts
            };
        }
    }, []);

    return (
        <>
            <MessageAlert open={alert.open} message={alert.message} onClose={handleClose} severity={alert.severity}/>
            <Router history={browserHistory}>
                <Layout routes={routes} />
            </Router>
        </>
    );
}