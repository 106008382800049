import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {StateProvider, } from "./StateContext";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: '#1C4E80', // Replace this with your desired primary color
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <StateProvider>
            <App/>
        </StateProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);


