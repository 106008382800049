import React, { useState } from "react";
import { useStateValue } from "../../../../StateContext";
import {convertIntoDate, updateNotification, withErrorHandlingAndLoading} from "../../../../constants/functions";
import { actionTypes } from "../../../../StateContext/types";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,Divider, Grid,  Box,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { Close as CloseIcon, Drafts as MarkEmailReadIcon } from "@material-ui/icons";

export const NotificationMenu = ({ setLoading, handleAlert }) => {
    const { notificationsState, notificationsDispatch } = useStateValue();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    const handleNotificationClick = withErrorHandlingAndLoading(async (item) => {
        if (!item.seen) {
            await updateNotification(item.id);
            notificationsDispatch({
                type: actionTypes.UPDATE_NOTIFICATION,
                id: item.id,
            });
        }
        setSelectedNotification(item);
        setDialogOpen(true);
    }, setLoading, handleAlert);

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedNotification(null);
    };

    const handleReadAll = withErrorHandlingAndLoading(async () => {
        const unreadNotifications = notificationsState.filter(noti => !noti.seen);
        for (const noti of unreadNotifications) {
            await updateNotification(noti.id);
        }
        notificationsDispatch({
            type: actionTypes.MARK_ALL_AS_READ,
        });
    }, setLoading, handleAlert);

    // Function to truncate the message to a specific length
    const truncateMessage = (message, maxLength = 50) => {
        if (message.length > maxLength) {
            return message.substring(0, maxLength) + "...";
        }
        return message;
    };

    return (
        <>
            <List style={{ padding: 10, minWidth: 180, maxWidth: 280 }}>
                <ListItem>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Notifications
                    </Typography>
                    <Tooltip title={'Mark all as read'}>
                        <IconButton color="primary" onClick={handleReadAll}>
                            <MarkEmailReadIcon />
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {notificationsState.length > 0 ? (
                    notificationsState.map((item) => (
                        <ListItem
                            button
                            style={{ minWidth: 180, margin: 5, borderRadius: 5 }}
                            selected={!item.seen}
                            key={item.id}
                            alignItems="flex-start"
                            onClick={async () => { await handleNotificationClick(item) }}
                        >
                            <ListItemAvatar>
                                <Avatar alt="Notification Icon" src="/static/images/avatar/1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.typeN}
                                secondary={
                                    <>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                        >
                                            {truncateMessage(item.message)}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="textSecondary"
                                        >
                                            {convertIntoDate(item.created)}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    ))
                ) : (
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt="No Notifications" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Notification"
                            secondary={
                                <>
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                                        {'There are no notifications ...'}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                )}
            </List>

            <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <MarkEmailReadIcon style={{ marginRight: 8 }} /> {/* Notification Icon */}
                            <Typography variant="h6">Notification Details</Typography>
                        </Box>
                        <IconButton onClick={handleDialogClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {selectedNotification && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                   <strong>Type:</strong>   {selectedNotification.typeN}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    <strong>Topic:</strong> {selectedNotification.topic}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Created:</strong> {convertIntoDate(selectedNotification.created)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    bgcolor="background.paper"
                                    p={2}
                                    borderRadius={4}
                                    border={1}
                                    borderColor="divider"
                                >
                                    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                        {selectedNotification.message}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>

                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};